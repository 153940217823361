:root {
  --canvasWidthVw: 100%;
  --canvasHeightVh: 85vh;
}

.canvasStyle {
  display: flex;
  position: relative;
  width: var(--canvasWidthVw);
  height: var(--canvasHeightVh);
  background: white;
  color: black;
  margin-top: 7px;
}

.toolboxMenu {
  background: rgba(148, 148, 148, 0.15);
  text-align: center;
  border: solid rgb(200, 200, 200) 1px;
  border-left: none;
}
.toolboxTitle {
  font-size: 20px;
  height: 50px;
}
.toolboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: calc(100% - 68px);
}
#toolboxGrid {
  height: 100%;
}

.statesContainer {
  position: relative;
  width: 100%;
  border: solid rgb(200, 200, 200) 1px;
}
.state {
  border: 1px #999 solid;
  text-align: center;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-height: 440px) {
  #toolboxGrid {
    height: unset !important;
  }
}
