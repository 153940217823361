.topBarStyle {
  display: flex;
  overflow: hidden;
  /* overflow-x: auto; */
  position: absolute;
  width: 100%;
  background: rgb(220, 240, 250);
  /* z-index: 1; */
  /* align-items: center; */
  /* padding: 0px 20px; */
  transition: all 0.2s ease-out;
  /* border: solid 1px black; */
  height: 90px;
}

.topBarLabel {
  display: flex;
  align-items: center;
  /* min-width: 120px; */
  border-radius: 30px;
  background: white;
  margin: 5px;
}
.topBarLabel:hover {
  background: azure;
}
.topBarLabel:active {
  background: deepskyblue;
}

.actionBubbles {
  display: flex;
  align-items: center;
  width: calc(100% - 120px);
  justify-content: space-evenly;
  margin-left: 50px;
}

.actionBubble {
  background: white;
  border-radius: 50px;
  height: 80%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px;
}
.actionBubble:hover {
  background: azure;
}
.actionBubble:active {
  background: DeepSkyBlue;
}

@media (max-width: 820px) and (min-width: 680px) {
  .actionBubbles {
    font-size: 0.9em;
  }
}
@media (max-width: 680px) and (min-width: 540px) {
  .actionBubbles {
    font-size: 0.8em;
  }
  .actionBubble {
    padding: 8px;
  }
}
@media (max-width: 540px) and (min-width: 450px) {
  .actionBubbles {
    font-size: 0.7em;
  }
  .actionBubble {
    padding: 6px;
    margin: 3px;
  }
}
@media (max-width: 450px) and (min-width: 380px) {
  .actionBubbles {
    font-size: 0.6em;
  }
  .actionBubble {
    padding: 4px;
    margin: 2px;
  }
}

@media (max-width: 380px) and (min-width: 320px) {
  .actionBubbles {
    font-size: 0.5em;
  }
  .actionBubble {
    padding: 2px;
    margin: 2px;
  }
}
@media (max-width: 320px) {
  .actionBubbles {
    font-size: 0.5em;
  }
  .actionBubble {
    padding: 1px;
    margin: 1px;
  }
}
